import { render, staticRenderFns } from "./tema.vue?vue&type=template&id=6c6bfc6a&scoped=true&"
import script from "./tema.vue?vue&type=script&lang=js&"
export * from "./tema.vue?vue&type=script&lang=js&"
import style0 from "./tema.vue?vue&type=style&index=0&id=6c6bfc6a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c6bfc6a",
  null
  
)

export default component.exports